@import "../../scss/variables";

.real-estate {
  .map-wrapper {
    position: relative;
  }
}

.real-estate__header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 1rem;

  h1 {
    font-size: 3.2rem;
    font-weight: 600;
    line-height: 3.36rem;
    letter-spacing: 0.02rem;
    color: rgba(12, 12, 12, 1);
    text-transform: uppercase;
    max-width: 72%;
  }
}

.header-actions__link {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.16rem;
  letter-spacing: 0.02rem;
  margin-right: 4.6rem;
  color: rgba(130, 130, 130, 1);
  white-space: nowrap;
  cursor: pointer;

  i {
    position: relative;
    top: 0.15rem;
    margin-right: 0.6rem;
  }

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }
}

.real-estate__header-actions {
  margin-top: 2.6rem;
  display: flex;
  align-items: center;
}

.header-actions__controls-item {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 117, 200, 1);

  i {
    &:before {
      color: #ffffff;
    }
  }

  &:hover {
    cursor: pointer;
    background-color: #ffffff;
    box-shadow: 0 0.4rem 1.5rem 0 #00000040;

    i {
      &:before {
        color: rgba(0, 117, 200, 1);
      }
    }
  }
}

.real-estate__preview {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 1rem;
}

.real-estate__preview-gallery {
  width: 55%;
}

.real-estate__preview-gallery-placeholder {
  min-height: 558px;
  border: 1px solid rgba(0, 117, 200, 0.09);
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    width: 66%;
    height: 66%;
    object-fit: cover;

    path {
      fill: #E1DACC;
    }
  }
}

.real-estate__preview-map {
  width: 45%;
}

.image-gallery-swipe {
  border-radius: 1.6rem;
  overflow: hidden;
}

.image-gallery-thumbnail {
  border-radius: 1.6rem;
  overflow: hidden;

  &:not(.active) {
    &:hover {
      cursor: pointer;
    }
  }
}
.image-gallery-fullscreen-button {
  padding: 0;
}

.image-gallery-icon.image-gallery-fullscreen-button {
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2rem;
  right: 2rem;
  background: rgba(0, 117, 200, 1);
  border-radius: 0.4rem;

  &:hover {
    background-color: #ffffff;
  }
}

.real-estate__preview-gallery + .real-estate__preview-map {
  margin-left: 2%;
}

.real-estate__preview-gallery {
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 100%;
  }
}

.image-gallery-icon {
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 2rem;
  right: 2rem;
  background: rgba(0, 117, 200, 1);
  border-radius: 0.4rem;
  padding: 0;

  &:hover {
    background-color: #ffffff;
  }

  .image-gallery-svg {
    height: 2.4rem;
    width: 2.4rem;
  }
}

.image-gallery-icon.image-gallery-right-nav {
  position: absolute;
  bottom: 0.5rem;
  right: 2rem;
  top: auto;
}

.image-gallery-icon.image-gallery-left-nav {
  position: absolute;
  bottom: 0.5rem;
  right: calc(4rem + 3.6rem);
  left: auto;
  top: auto;
}

.real-estate__preview-map {
  div[data-testid="map"] {
    height: 22.5rem !important;

    > div:first-child {
      > div:first-child {
        border-radius: 1.6rem;
        overflow: hidden;
      }
    }
  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    .gmnoprint {
      bottom: -6.5rem;
      top: auto !important;
    }
  }
}

.real-estate__buy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4.4rem;
}

.real-estate__buy-price {
  font-size: 3.2rem;
  font-weight: 700;
  line-height: 3.36rem;
  letter-spacing: 0.02rem;
}

.real-estate__buy-action {
  max-width: 28.3rem;
  width: 100%;
}

.real-estate__address-location {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.92rem;
  letter-spacing: 0.02rem;
  width: 100%;
  padding: 2.4rem 0.1rem;
  border-bottom: 2px solid rgba(0, 117, 200, 0.09);

  i {
    margin-right: 0.9rem;
    position: relative;
    top: 0.1rem;
    font-size: 1.4rem;
  }
}

.real-estate__marketing-description {
  margin: 3rem 1rem 0 1rem;
}

.real-estate__characteristics-title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.16rem;
  letter-spacing: 0.02rem;
  color: rgba(12, 12, 12, 1);
  text-transform: uppercase;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
}

.real-estate__characteristics-row {
  display: flex;
  align-items: flex-start;

  &--extras {
    flex-wrap: wrap;

    .real-estate__characteristics-col-item {
      white-space: nowrap;
    }

    .real-estate__characteristics-col:not(:last-child):not(:nth-last-child(2)) {
      margin-bottom: 2.4rem;
    }
  }
}

.real-estate__characteristics-row-separator {
  height: 2px;
  width: 100%;
  background-color: rgba(0, 117, 200, 0.09);
  margin-top: 2.4rem;
  margin-bottom: 2.4rem;
}

.real-estate__characteristics-col {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.real-estate__characteristics-col-item {
  display: flex;
  align-items: center;
}

.real-estate__characteristics-col-item + .real-estate__characteristics-col-item {
  margin-top: 2.4rem;
}

.real-estate__characteristics-col-item-icon {
  i {
    &:before {
      font-size: 2rem;
    }
  }
}

.real-estate__characteristics-col-item-name {
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.47rem;
  letter-spacing: 0.02rem;
  margin-left: 0.8rem;
  margin-right: 0.4rem;
}

.real-estate__characteristics-col-item-value {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.47rem;
  letter-spacing: 0.02rem;
}

.real-estate__preview + .real-estate__marketing-description {
  display: none;
}

.real-estate__header + .real-estate__buy {
  display: none;
  margin-left: 1rem;
  margin-right: 1rem;
}

.image-gallery-swipe {
  display: block;
  height: 437px;
}

.image-gallery-slide, .image-gallery-slides {
  height: 100%;
  object-fit: cover;
}

.image-gallery-image {
  height: 100%;
  object-fit: cover !important;
  width: 100%;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}

.image-gallery-content.image-gallery-thumbnails-bottom.fullscreen {
  .image-gallery-swipe {
    display: block;
    height: 90vh;
  }
}

@media only screen and (max-width: 1040px) {
  .real-estate__preview {
    flex-wrap: wrap;
  }
  .real-estate__preview-gallery,
  .real-estate__preview-map {
    width: 100%;
  }

  .real-estate__preview-gallery {
    .real-estate__marketing-description {
      display: none;
    }
  }

  .real-estate__preview-map {
    margin-top: 2rem;
    margin-left: 0 !important;

    .real-estate__buy {
      display: none;
    }
  }

  .real-estate__header + .real-estate__buy {
    display: flex;
  }

  .real-estate__preview + .real-estate__marketing-description {
    display: flex;
  }
}

@media only screen and (max-width: 768px) {
  .real-estate__preview-gallery-placeholder {
    min-height: auto;
  }
}

@media only screen and (max-width: 656px) {
  .image-gallery-fullscreen-button,
  .image-gallery-left-nav,
  .image-gallery-right-nav {
    display: none !important;
  }

  .real-estate__header {
    flex-wrap: wrap;
  }

  .real-estate__header h1 {
    max-width: 100%;
  }

  .real-estate__header-actions {
    display: none;
  }

  .real-estate__buy-action {
    max-width: 12.3rem;
  }

  .real-estate__preview-map {
    margin-top: 4rem;
  }

  .real-estate__marketing-description {
    margin-top: 4rem;
  }

  .real-estate + .calculator {
    margin-top: 4rem;
    margin-bottom: 1rem !important;
  }
}

@media print {
  html, body{
    // height: 297mm;
    // width: 210mm;
    margin: 0;
  }

  header, footer {
    display: none;
  }

  .content {
    margin-top: 0;
  }

  .image-gallery-wrapper {
    flex-basis: 60%;
    max-width: 60%;
    width: 60% !important;
  }

  .image-gallery-wrapper + div {
    flex-basis: 40%;
    max-width: 40%;
    width: 40% !important;
  }

  .contacts-print {
    img {
      margin: 0 !important;
    }
  }

  .real-estate-contacts {
    padding: 0;
  }

  .real-estate-address-location {
    bottom: -0.1rem;
    right: -0.1rem;
  }

  .print-button {
    display: none;
  }

  .real-estate-details-buy {
    display: none;
  }

  .image-gallery-icon, .image-gallery-bullets {
    display: none !important;
  }

  .calculator {
    display: none;
  }

  .real-estate__header-actions {
    display: none;
  }

  .real-estate__buy-action {
    display: none;
  }
}
