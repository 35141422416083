@import "../../../scss/variables";

.footer {
  border-top: 1px solid #828282;
  width: 100%;
  max-width: 121.2rem;
  margin: auto auto 0 auto;

  > .row > div[class*="col"] {
    padding: 0.6rem;
  }
}

.footer__left {
  margin-top: 7.6rem;
  margin-bottom: 2.6rem;
}

.footer__middle {
  margin-top: 4.8rem;
  margin-bottom: 2.6rem;
}

.footer__right {
  margin-top: 4.8rem;
}

.footer__copyright {
  color: #828282;
  font-size: 1.8rem;
  margin-top: 8.8rem;
}

.footer__navigation-item {
  font-size: 1.8rem;
  line-height: 2.4rem;
  font-weight: 300;
  cursor: pointer;

  > a {
    text-decoration: none;
    color: $text-base-color;
  }

  &.footer__navigation-item-privacy {
    margin-top: 4.6rem;

    > a {
      color: #828282;
    }
  }

  &:hover {
    -webkit-text-fill-color: lighten($text-base-color, 20%);
    -webkit-text-stroke-width: 0.045rem;
    -webkit-text-stroke-color: lighten($text-base-color, 20%);
  }

  &:active {
    -webkit-text-fill-color: lighten($text-base-color, 30%);
    -webkit-text-stroke-width: 0.045rem;
    -webkit-text-stroke-color: lighten($text-base-color, 30%);
  }
}

.footer__navigation-item + .footer__navigation-item:not(:last-child) {
  margin-top: 6px;
}

.contact-row {
  font-size: 1.8rem;
  line-height: 1.8rem;
  font-weight: 300;
  margin-bottom: 1.8rem;
  display: flex;
  align-items: flex-start;
  text-align: left;

  i {
    margin-right: 1rem;
  }

  > a {
    text-decoration: none;
    color: $text-base-color;
  }

  &:not(&--location):hover {
    -webkit-text-fill-color: lighten($text-base-color, 20%);
    -webkit-text-stroke-width: 0.045rem;
    -webkit-text-stroke-color: lighten($text-base-color, 20%);
  }

  &:not(&--location):active {
    -webkit-text-fill-color: lighten($text-base-color, 30%);
    -webkit-text-stroke-width: 0.045rem;
    -webkit-text-stroke-color: lighten($text-base-color, 30%);
  }
}

.social-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 3rem;

  .social-icon {
    i:before {
      font-size: 2.4rem;
      color: $clr-704B29;
    }

    &:not(&--location):hover {
      -webkit-text-fill-color: lighten($clr-704B29, 20%);
      -webkit-text-stroke-width: 0.045rem;
      -webkit-text-stroke-color: lighten($clr-704B29, 20%);
    }

    &:not(&--location):active {
      -webkit-text-fill-color: lighten($clr-704B29, 30%);
      -webkit-text-stroke-width: 0.045rem;
      -webkit-text-stroke-color: lighten($clr-704B29, 30%);
    }

    > a {
      text-decoration: none;
    }
  }

  .social-icon + .social-icon {
    margin-left: 1.8rem;
  }
}

@media only screen and (max-width: 868px) {
  .footer__logotype {
    width: 20rem;
  }
}

@media only screen and (max-width: 656px) {
  .footer {
    margin-bottom: 3.2rem;
  }

  .footer__middle {
    display: none;
  }

  .footer__left,
  .footer__middle {
    text-align: center;
  }

  .footer__middle, .footer__right {
    margin-top: 3.2rem;
    margin-bottom: 0;
  }

  .footer__right {
    max-width: 28.5rem;
    margin-left: auto;
    margin-right: auto;
  }

  .footer__left {
    margin: 3.2rem auto 0 auto;
  }

  .footer__info {
    text-align: center;


    .footer__copyright,
    .footer__navigation-item {
      margin-top: 0;
      margin-bottom: 0;
      display: inline-block;
    }

    .footer__navigation-item {
      margin-left: 2.4rem;
    }
  }

  .social-container {
    justify-content: center;
  }

  .contact-row {
    text-align: left;
  }
}
