@import "../../../scss/variables";

.popover-container {
  position: relative;
  display: inline-flex;
  width: 100%;
}

.popover-trigger {
  width: 100%;
}

.popover-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 0.2rem;
  white-space: nowrap;
  background-color: $text-secondary-color;
  box-shadow: 0 0.4rem 1.5rem 0 #00000040;
  z-index: 5;
  border-radius: $border-radius;
}
