@import "../../scss/variables";
$close: $clr-1C1B1F;

.sell {
  padding-left: 1rem;
  padding-right: 1rem;

  .sell-form__header {
    height: 5.2rem;
    line-height: 5.2rem;
  }

  &:after {
    content: "";
    position: absolute;
    top: -38.6rem;
    left: -120rem;
    right: -120rem;
    height: 166.3rem;
    background: rgba(0, 117, 200, 0.09);
    transform: rotate(25deg);
    z-index: -1;
  }

  .real-estate__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .real-estate__logotype {
    width: 13rem;
  }
  .real-estate__subtitle {
    height: 1.6rem;
    line-height: 2rem;
    color: $text-base-color;
    font-size: 1.4rem;
  }
  .real-estate__description {
    font-size: 16px;
    font-weight: 500;
    margin: 1rem 0;
  }

  .modal-content {
    padding-top: 1rem;
    padding-bottom: 0.6rem;
  }

  div.privacy {
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;

    .checkbox {
      justify-content: center;

      &-invalid + a {
        color: #bf1650 !important;
      }
    }
  }

  label.checkbox + a {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-left: 0.45rem;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 400;
    -webkit-user-select: none;
    user-select: none;
    color: #221122;
  }


  .button[type="submit"] + .button {
    display: none !important;
  }
}

.sell + .calculator {
  margin-top: 12rem;
}

.sell-section {
  position: relative;
  max-width: 43.9rem;
}

.sell-section + .sell-section {
  margin-top: 4.5rem;
}

.sell-section__counter {
  font-size: 13.2rem;
  font-weight: 500;
  line-height: 15.84rem;
  text-align: left;
  color: rgba(0, 117, 200, 0.56);
  position: absolute;
  left: -8px;
}

.sell-section__title {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 2.16rem;
  letter-spacing: 0.02rem;
  color: rgba(12, 12, 12, 1);
  background-color: transparent;
  max-width: 23.8rem;
}

.sell-section__description {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.92rem;
  letter-spacing: 0.02rem;
  color: rgba(130, 130, 130, 1);
  background-color: transparent;
  margin-top: 1.6rem;
}

.sell__action {
  margin: 16.6rem auto 0 auto;
  max-width: 36.6rem;
  position: relative;
}

.sell-section__content-container {
  padding-top: 4.1rem;
  padding-left: 7.2rem;
}

.sell-section-middle {
  margin: 0 auto;
}

.sell-section-right {
  margin-left: auto;
}

.sell-section-arrow-right {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -20.8rem;
    margin: 0 9.5rem 0 auto;
    left: 0;
    right: 0;
    background: url("./images/arrow-right.svg");
    width: 11.8rem;
    height: 16.6rem;
  }
}

.sell-section-arrow-left {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: -20.8rem;
    margin: 0 auto 0 9.5rem;
    left: 0;
    right: 0;
    background: url("./images/arrow-left.svg");
    width: 11.8rem;
    height: 16.6rem;
  }
}

.sell-miniature {
  position: absolute;
}

.sell-miniature-top {
  top: 18.7rem;
  right: -9.55rem;
  background: url("./images/square.svg");
  background-repeat: no-repeat;
  width: 19.1rem;
  height: 19.1rem;
}

.sell-miniature-middle {
  top: 94.3rem;
  left: 4.149rem;
  background: url("./images/square.svg");
  background-repeat: no-repeat;
  width: 19.1rem;
  height: 19.1rem;
}

.sell-miniature-triangle {
  top: 149.2rem;
  right: 15.858rem;
  background: url("./images/triangle.svg");
  background-repeat: no-repeat;
  width: 19.6rem;
  height: 15.5rem;
}

.sell-miniature-action {
  top: -3.7rem;
  left: -5rem;
  background: url("./images/rectangle.svg");
  background-repeat: no-repeat;
  width: 19.6rem;
  height: 15.5rem;
}

.sell-form {
  background-color: #fff;
  border-radius: 2.4rem;
  box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.2509803922);
}

.sell-form__header {
  height: 5.2rem;
  line-height: 5.2rem;
  background-color: $clr-E1DACC;
  font-weight: 500;
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem;

  div[class*='col'] {
    padding-top: 0;
    padding-bottom: 0;
    height: 5.2rem;
    font-size: 16px;
  }
}

.sell-form {
  background-color: #ffffff;
  border-radius: 2.4rem;
  max-width: 460px;

  .sell-form__header {
    font-size: 1.4rem;

    div[class*=col] {
      font-size: 1.4rem;
    }

    .icon-arrow-left-2 {
      &:hover {
        cursor: pointer;
      }
    }
    .icon-arrow-left-2:before {
      font-size: 2.4rem;
      color: #1C1B1F;
      line-height: 4.6rem;
      position: relative;
      top: 2px;
      left: 5px;
    }
  }

  div.privacy {
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;

    label + a {
      display: inline-flex;
      align-items: center;
      position: relative;
      padding-left: 0.45rem;
      cursor: pointer;
      font-size: 1.6rem;
      font-weight: 400;
      -webkit-user-select: none;
      user-select: none;
      color: #221122;
    }

    .checkbox-invalid + a {
      color: #bf1650 !important;
    }
  }
}

@media only screen and (max-width: 1280px) {
  .sell-section-arrow-right {
    &:before {
      margin: 0 20.5rem 0 auto;
    }
  }

  .sell-section-arrow-left {
    &:before {
      margin: 0 auto 0 20.5rem;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .sell-section-arrow-right {
    &:before {
      margin: 0 27.5rem 0 auto;
    }
  }

  .sell-section-arrow-left {
    &:before {
      margin: 0 auto 0 27.5rem;
    }
  }
}

@media only screen and (max-width: 830px) {
  .sell-section-arrow-right {
    &:before {
      margin: 0 30.5rem 0 auto;
    }
  }

  .sell-section-arrow-left {
    &:before {
      margin: 0 auto 0 30.5rem;
    }
  }
}

@media only screen and (max-width: 830px) {
  .sell-section-arrow-right {
    &:before {
      margin: 0 30.5rem 0 auto;
    }
  }

  .sell-section-arrow-left {
    &:before {
      margin: 0 auto 0 30.5rem;
    }
  }
}

@media only screen and (max-width: 768px) {
  .sell-section {
    margin: auto;
  }

  .sell-section__content-container {
    padding-top: 4.1rem;
    padding-left: 3.2rem;
  }

  .sell-form {
    margin-top: 9rem !important;

    .steps-progress-wrapper {
      padding: 2rem 2rem 0 2rem;
    }

    .sell-form__header {
      div[class*='col'] {
        padding: 0 1.2rem;
      }

      div[class*='col']:last-child {
        text-align: right;
      }

      .row {
        padding: 0;
      }

      .icon-arrow-left-2:before {
        font-size: 2.4rem;
        color: #1C1B1F;
        line-height: 4.6rem;
        position: relative;
        top: 2px;
        left: 5px;
      }
    }

    .field-regexp,
    .field-text,
    .field-phone,
    .select__trigger,
    .select__dropdown-item {
      height: 6.6rem;
      font-size: 1.6rem;
    }

    .field-textarea,
    .field-postfix {
      font-size: 1.6rem;
    }

    .select__dropdown {
      top: 6.6rem;
    }

    .modal-content {
      padding: 0.6rem;

      .row > div[class*="col"] {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
      }

      .button--small {
        font-size: 1.4rem;
        height: 5.6rem;
        padding: 0 1rem;
      }
    }

    .ta-right {
      text-align: left;
      padding-bottom: 0;
      padding-left: 4rem;
    }

    form {
      position: relative;
    }

    .modal-actions {
      width: 100%;
      background-color: $text-secondary-color;

      div[class*='col'] {
        padding: 1rem
      }

      .button {
        height: 6.6rem;
        font-size: 1.6rem;
      }
    }

    div.privacy {
      padding-bottom: 2rem;
      display: flex;
      justify-content: center;

      label + a {
        display: inline-flex;
        align-items: center;
        position: relative;
        padding-left: 0.45rem;
        cursor: pointer;
        font-size: 1.6rem;
        font-weight: 400;
        -webkit-user-select: none;
        user-select: none;
        color: #221122;
      }

      .checkbox-invalid + a {
        color: #bf1650 !important;
      }
    }
  }

  .sell-section-arrow-left,
  .sell-section-arrow-right {
    &:before {
      background: url("./images/arrow-left.svg");
      margin: 0 auto 0 26.5rem;
      transform: rotate(-45deg);
      width: 4.1rem;
      height: 5.8rem;
      background-size: cover;
      bottom: -6.8rem;
    }
  }

  .sell-section + .sell-section {
    margin-top: 4rem;
  }

  .sell-section__title {
    max-width: 22.8rem;
    margin-bottom: -0.9rem;
  }
}
