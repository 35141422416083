@import "../../../scss/variables";

.field-static {
  height: 4.6rem;
  background-color: $field-background;
  padding: 0 2.4rem;
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 100%;
  font-weight: 300;
  color: $text-base-color;
  width: 100%;
  border: 0.1rem solid transparent;
  transition: all .3s;
  position: relative;
  border-radius: $border-radius;

  &:not([disabled]) {
    cursor: pointer;
    &:hover {
      background-color: darken($field-background, 2%);
    }

    &:focus {
      background-color: darken($field-background, 2%);
      border-color: 0.1rem solid darken($field-background, 3%);;
      outline: none;
    }
  }

  [disabled] {
    background-color: lighten($field-background, 1%);
  }

  &--with-postfix {
    padding-right: 4.6rem;
  }
}

.field-static__placeholder {
  color: rgb(117, 117, 117);
  font-weight: 300;
}

.field-static__postfix {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 2.4rem;
  margin: auto;
  display: flex;
  align-items: center;
}
