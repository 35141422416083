@import "../../../../scss/variables";

.catalog-filter {
  margin-top: 3.2rem;

  .popover-content {
    div[class*="col"] {
      padding: 0.6rem !important;
    }

    button {
      font-size: 1.4rem;
      max-width: 9rem;
      width: auto;
    }
  }

  .municipality-combobox {
    .popover-content {
      min-width: 22rem;
      left: 0;
      transform: none;
      button {
        width: 100%;
        max-width: 100%;
      }
    }

    .field-text {
      padding-right: 2.2rem;
    }
  }

  .real-estate-type-select {
    .select__dropdown {
      min-width: 18rem;
    }
  }

  .real-estate-bedrooms,
  .real-estate-living-area {
    .popover-content {
      min-width: 220px;
    }
  }

  .real-estate-price {
    .popover-content {
      min-width: 248px;
    }
  }

  .filter-separate {
    height: 0.1rem;
    margin: 0 0.6rem;
    background-color: $field-background;
  }

  .button--extra-small {
    padding-left: 1.2rem !important;
  }

  .button {
    &--transparent {
      background-color: transparent;
      color: $text-base-color;
    }

    &--extra-small {
      height: 3rem;
      font-size: 1.6rem;
    }

    &--content {
      width: auto;
      padding: 0;
    }
  }

  .field-regexp {
    &--extra-small {
      height: 3.6rem;
      font-size: 1.6rem;
      padding: 0 1.2rem;
    }
  }

  .field-currency {
    &--extra-small {
      height: 3.6rem;
      font-size: 1.6rem;
      padding: 0 1.2rem;
    }
  }

  .field-with-postfix {
    .field-regexp--extra-small {
      padding-right: 2.4rem;

      + .field-postfix {
        right: 2rem;
      }
    }

    .field-currency--extra-small {
      padding-right: 2.4rem;

      + .field-postfix {
        right: 2rem;
      }
    }
  }

  .field-text {
    &--extra-small {
      height: 3.6rem;
      font-size: 1.6rem;
      padding: 0 1.2rem;
    }
  }

  div.popover-container.municipality-combobox {
    position: relative;
  }
}

@media only screen and (max-width: 676px) {
  .catalog-filter {
    margin-top: 0.8rem;

    .searcher .municipality-combobox .field-prefix {
      left: 1.4rem;
    }

    .searcher .municipality-combobox .field-text {
      padding-left: 4.9rem;
    }

    .municipality-combobox .popover-content > button {
      padding-left: 2.4rem;
    }

    .real-estate-type-select {
      //padding-right: 0.4rem;

      .select__dropdown {
        min-width: 22rem;
      }
    }

    //.real-estate-municipality-combobox {
    //  padding-left: 0.4rem;
    //}
    //
    //.real-estate-bedrooms {
    //  padding-right: 0.4rem;
    //}
    //
    //.real-estate-living-area {
    //  padding-left: 0.4rem;
    //}

    .real-estate-type-select,
    .real-estate-bedrooms,
    .real-estate-living-area,
    .real-estate-municipality-combobox,
    .real-estate-price {
      padding-bottom: 0.4rem;
    }

    .real-estate-living-area {
      .popover-content {
        margin-left: 14px;
      }
    }

    .real-estate-bedrooms {
      .popover-content {
        margin-left: -17px;
      }
    }

    .popover-container.municipality-combobox {
      .popover-content {
        margin-left: -33px;
      }
    }

    .button--extra-small {
      font-size: 1.3rem;
    }

    .municipality-combobox__trigger .field-text, .select__trigger--placeholder, .field-static__placeholder {
      font-size: 1.6rem;
    }
  }
}
