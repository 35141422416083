@import "../../../../scss/variables";

.ReactModal__Content-success {
  height: auto;
  margin: 0 1rem;

  .modal-content {
    padding: 2.6rem;
    max-height: 30.8rem;
    overflow: auto;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 300;
    color: $text-base-color;
  }

  .button + label {
    margin-left: 2.4rem;
  }

  .col-success {
    text-align: center;
  }

  .col-heart {
    .icon-heart {
      &:before {
        font-size: 3.4rem;
        color: rgba(0, 117, 200, 0.5607843137);
      }
    }
  }

  .col-title {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.92rem;
    letter-spacing: 0.03rem;
    text-align: center;
    text-transform: uppercase;
    color: $text-base-color;
  }

  .col-description {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2.4rem;
    letter-spacing: 0.03rem;
    text-align: center;
    width: 25rem;
  }
}

@media only screen and (max-width: 768px) {
  .ReactModal__Content-privacy {
    height: 100%;

    .modal-header {
      div[class*='col']:nth-child(2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .modal-content {
      max-height: 100%;
    }
  }
  .ReactModal__Content-success {
    .modal-actions {
      padding: 3rem !important;
    }
  }
}
