@import "../../../scss/variables";

.field-text-wrapper {
  position: relative;
}

.field-text {
  height: 4.6rem;
  background-color: $field-background;
  padding: 0 2.4rem;
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 100%;
  font-weight: 300;
  color: $text-base-color;
  width: 100%;
  border: 0.1rem solid transparent;
  transition: all .3s;
  border-radius: $border-radius;

  &:not(&:disabled) {
    cursor: text;
    &:hover {
      background-color: darken($field-background, 2%);
    }

    &:focus {
      background-color: darken($field-background, 2%);
      border-color: 0.1rem solid darken($field-background, 3%);;
      outline: none;
    }
  }

  &:disabled {
    background-color: lighten($field-background, 1%);
  }

  &--postfix {
    padding-right: 4.8rem;
    display: inline-flex;
  }

  &--prefix {
    padding-left: 4.8rem;
    display: inline-flex;
  }
}

.field-with-prefix {
  position: relative;

  .field-text {
    padding-left: 5.6rem;
  }

  .field-postfix {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 4rem;
    margin: auto;
    height: 1.6rem;
  }
}

.field-with-postfix {
  position: relative;

  .field-text {
    padding-right: 5.6rem;
  }

  .field-postfix {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 4rem;
    margin: auto;
    height: 1.6rem;
    font-size: 1.4rem;
  }
}

.field-text-invalid {
  border-width: 0 0 0 0.4rem;
  border-style: solid;
  border-color: rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) $clr-bf1650;
  border-image: initial;
}
