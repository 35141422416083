@import "../../../scss/variables";

.button {
  border: none;
  height: 5.6rem;
  padding: 0 1.2rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: $text-secondary-color;
  background-color: $clr-704B29;
  width: 100%;
  font-weight: 400;
  text-transform: uppercase;
  border-radius: $border-radius;

  &--light {
    background-color: $field-background;
    color: $text-base-color;
  }

  &--small {
    height: 4.6rem;
    padding: 0 2.2rem;
    font-size: 1.2rem;
    font-weight: 400;
  }

  &--circle {
    border-radius: 2.4rem;
  }

  &--ha-start {
    justify-content: flex-start;
  }

  &:not(&:disabled) {
    cursor: pointer;

    &:hover {
      opacity: .9;
    }

    &:active {
      opacity: .85;
      outline: none;
    }
  }

  &:disabled {
    opacity: 0.55;
  }

  i[class*="icon"] {
    margin-right: 0.8rem;
    &:before {
      font-size: 2.4rem;
    }
  }
}
