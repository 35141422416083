@import "../../../scss/variables";
$close: $clr-1C1B1F;

.modal-header {
  height: 4.8rem;
  line-height: 4.8rem;
  background-color: $clr-E1DACC;
  font-weight: 500;
  border-top-left-radius: 2.4rem;
  border-top-right-radius: 2.4rem;

  .icon-close {
    width: 4.8rem;
    height: 4.8rem;
    line-height: 4.8rem;
    cursor: pointer;

    &:before {
      font-size: 2.4rem;
      color: $close;
    }

    &:hover {
      &:before {
        color: lighten($close, 25%);
      }
    }

    &:active {
      &:before {
        color: lighten($close, 35%);
      }
    }
  }

  div[class*='col'] {
    padding-top: 0;
    padding-bottom: 0;
    height: 4.8rem;
    font-size: 16px;
  }
}

.modal-content {
  padding: 2.6rem;
}

.modal-actions {
  padding: 2.6rem;
  border-bottom-left-radius: 2.4rem;
  border-bottom-right-radius: 2.4rem;

  div[class*='col'] {
    padding-top: 0;
    padding-bottom: 0;
  }

  button {
    font-size: 1.4rem;
  }
}
