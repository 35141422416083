@font-face {
  src: url("../../public/fonts/Poppins-Thin.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 100;
  font-style: normal;
}

@font-face {
  src: url("../../public/fonts/Poppins-ThinItalic.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 100;
  font-style: italic;
}

@font-face {
  src: url("../../public/fonts/Poppins-ExtraLight.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 200;
  font-style: normal;
}

@font-face {
  src: url("../../public/fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 200;
  font-style: italic;
}

@font-face {
  src: url("../../public/fonts/Poppins-Light.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 300;
  font-style: normal;
}

@font-face {
  src: url("../../public/fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 300;
  font-style: italic;
}

@font-face {
  src: url("../../public/fonts/Poppins-Regular.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
}

@font-face {
  src: url("../../public/fonts/Poppins-Medium.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
}

@font-face {
  src: url("../../public/fonts/Poppins-MediumItalic.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 500;
  font-style: italic;
}

@font-face {
  src: url("../../public/fonts/Poppins-SemiBold.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
}

@font-face {
  src: url("../../public/fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 600;
  font-style: italic;
}

@font-face {
  src: url("../../public/fonts/Poppins-Bold.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
}

@font-face {
  src: url("../../public/fonts/Poppins-BoldItalic.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 700;
  font-style: italic;
}

@font-face {
  src: url("../../public/fonts/Poppins-ExtraBold.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 800;
  font-style: normal;
}

@font-face {
  src: url("../../public/fonts/Poppins-ExtraBoldItalic.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 800;
  font-style: italic;
}

@font-face {
  src: url("../../public/fonts/Poppins-Black.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 900;
  font-style: normal;
}

@font-face {
  src: url("../../public/fonts/Poppins-BlackItalic.ttf") format("truetype");
  font-family: "Poppins";
  font-weight: 900;
  font-style: italic;
}

@font-face {
  src: url("../../public/fonts/LondrinaSketch-Regular.ttf") format("truetype");
  font-family: "LondricaSketch";
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../../public/fonts/icomoon.eot?29534d');
  src:  url('../../public/fonts/icomoon.eot?29534d#iefix') format('embedded-opentype'),
  url('../../public/fonts/icomoon.ttf?29534d') format('truetype'),
  url('../../public/fonts/icomoon.woff?29534d') format('woff'),
  url('../../public/fonts/icomoon.svg?29534d#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tik:before {
  content: "\e93c";
}
.icon-linkedin:before {
  content: "\e93d";
}
.icon-flat-icon .path1:before {
  content: "\e93a";
  color: rgb(12, 12, 12);
}
.icon-flat-icon .path2:before {
  content: "\e93b";
  margin-left: -1.05859375em;
  color: rgb(0, 0, 0);
}
.icon-clock:before {
  content: "\e936";
}
.icon-hands:before {
  content: "\e937";
}
.icon-stars:before {
  content: "\e938";
}
.icon-home-funny:before {
  content: "\e939";
}
.icon-balcony:before {
  content: "\e900";
}
.icon-basement:before {
  content: "\e901";
}
.icon-arrow-down:before {
  content: "\e902";
}
.icon-arrow-left-2:before {
  content: "\e903";
}
.icon-arrow-left:before {
  content: "\e904";
}
.icon-arrow-right:before {
  content: "\e905";
}
.icon-auto-key:before {
  content: "\e906";
}
.icon-central-heating:before {
  content: "\e907";
}
.icon-circle-minus:before {
  content: "\e908";
}
.icon-circle-plus:before {
  content: "\e909";
}
.icon-conditioner:before {
  content: "\e90a";
}
.icon-elevator:before {
  content: "\e90b";
}
.icon-equipped-kitchen:before {
  content: "\e90c";
}
.icon-garden:before {
  content: "\e90d";
}
.icon-heart:before {
  content: "\e90e";
}
.icon-home:before {
  content: "\e90f";
}
.icon-info:before {
  content: "\e910";
}
.icon-location:before {
  content: "\e911";
}
.icon-main:before {
  content: "\e912";
}
.icon-phone:before {
  content: "\e913";
}
.icon-search:before {
  content: "\e914";
}
.icon-snowflake:before {
  content: "\e915";
}
.icon-terrace:before {
  content: "\e916";
}
.icon-close:before {
  content: "\e917";
}
.icon-valencia:before {
  content: "\e918";
}
.icon-chair-sun:before {
  content: "\e919";
}
.icon-waves:before {
  content: "\e91a";
}
.icon-building:before {
  content: "\e91b";
}
.icon-earth:before {
  content: "\e91c";
}
.icon-search-list:before {
  content: "\e91d";
}
.icon-dollar:before {
  content: "\e91e";
}
.icon-eye:before {
  content: "\e91f";
}
.icon-person:before {
  content: "\e920";
}
.icon-earth-2:before {
  content: "\e921";
}
.icon-search-folder:before {
  content: "\e922";
}
.icon-edit-folder:before {
  content: "\e923";
}
.icon-green:before {
  content: "\e924";
}
.icon-floor:before {
  content: "\e925";
}
.icon-minus:before {
  content: "\e926";
}
.icon-plus:before {
  content: "\e927";
}
.icon-building1:before {
  content: "\e928";
}
.icon-search1:before {
  content: "\e929";
}
.icon-arrow:before {
  content: "\e92a";
}
.icon-email:before {
  content: "\e92b";
}
.icon-facebook:before {
  content: "\e92c";
}
.icon-instagram:before {
  content: "\e92d";
}
.icon-phone2:before {
  content: "\e92e";
}
.icon-telegram:before {
  content: "\e92f";
}
.icon-whatsapp:before {
  content: "\e930";
}
.icon-Bath:before {
  content: "\e931";
}
.icon-sq-all:before {
  content: "\e932";
}
.icon-sq-live:before {
  content: "\e933";
}
.icon-bed:before {
  content: "\e934";
}
.icon-local:before {
  content: "\e935";
}
.icon-phone11:before {
  content: "\e943";
}
.icon-date:before {
  content: "\e953";
}
.icon-printer1:before {
  content: "\e955";
}

