@import "../../../../scss/variables";

.ReactModal__Overlay {
  div.ReactModal__Content-feedback {
    max-width: 71.9rem;

    .modal-header {
      background-color: #b4e0ff;
    }

    .modal-content,
    .modal-actions {
      background-color: $text-secondary-color;
    }

    h3 {
      font-size: 2.5rem;
      font-weight: 500;
      line-height: 7.7rem;
      text-align: left;
      color: $text-base-color;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    p {
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 2.7rem;
      text-align: left;
      color: $text-base-color;
      margin: 0;
    }

    .feedback-img {
      width: 8rem;
      height: 8rem;
      position: absolute;
      left: 1.6rem;
      top: 2.6rem;
      display: block;
    }
  }
}



@media only screen and (max-width: 1040px) {
  div.ReactModal__Content-feedback {
    .modal-actions {
      div[class*=col]:nth-child(1) {
        padding-bottom: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 657px) {
  div.ReactModal__Content-feedback {
    justify-content: center;
    background-color: transparent !important;
    margin-left: 0.8rem;
    margin-right: 0.8rem;

    .modal-content {
      padding-bottom: 0;
    }

    .modal-actions div[class*=col] {
      padding-top: 0 !important;
    }

    .modal-actions div[class*=col]:nth-child(1) {
      text-align: center;
    }
  }
}
