@import "../../../../scss/variables";

.searcher {
  margin-top: 2.1rem;
  width: 100%;

  .popover-content {
    border-radius: 1.6rem;
    overflow: hidden;
  }

  .popover-container.searcher__field-province-dropdown-wrapper,
  .popover-container.searcher__field-re-dropdown-wrapper,
  .popover-container.searcher__field-price-dropdown-wrapper {
    $bg: $field-background;
    width: auto;

    .popover-content button {
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 300;
      padding: 1.2rem 3.3rem;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      background-color: transparent;
      border-color: transparent;
      text-align: left;
      display: flex;
      flex-wrap: wrap;

      &:focus, &:active {
        outline: none;
        border-color: transparent;
      }

      &:hover:not(&:disabled) {
        background-color: darken($bg, 4%);
        cursor: pointer;
      }

      &:active:not(&:disabled) {
        background-color: darken($bg, 8%);
        cursor: pointer;
      }
    }
  }

  .popover-container.searcher__field-re-dropdown-wrapper{
    border-left: 1px solid rgb(130, 130, 130);
    min-width: 169px;
  }

  .popover-container.searcher__field-price-dropdown-wrapper {
    border-left: 1px solid rgb(130, 130, 130);
    min-width: 164px;
  }
}

.searcher__field {
  height: 6.6rem;
  display: flex;
  align-items: center;
  background-color: $text-secondary-color;
  position: relative;
  border-radius: 1.6rem;
  width: fit-content;
  padding-right: 12rem;
}

.searcher__field-dropdown-item {
  i[class*="icon"]:not(.icon-arrow) {
    margin-right: 0.6rem;
    position: relative;
    top: -0.2rem;

    &:before {
      font-size: 2.3rem;
    }
  }

  > div {
    display: flex;
    align-items: center;
  }

  &.true {
    > div {
      font-weight: 500;
    }
  }

  &.searcher__field-dropdown-item--default {
    opacity: 0.45;
  }
}

.searcher__field-dropdown-placeholder {
  height: 6.6rem;
  width: auto;
  font-size: 1.4rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  line-height: 1.68rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;

  i[class*="icon"]:not(.icon-arrow) {
    margin-right: 0.6rem;

    &:before {
      font-size: 2.3rem;
    }
  }

  .icon-arrow {
    margin-left: 0.6rem;
    position: relative;
    top: 0.2rem;

    &:before {
      font-size: 1.1rem;
    }
  }

  span {
    position: relative;
    top: 0.2rem;
    min-width: 7.2rem;
    text-align: center;
  }

  &:hover {
    cursor: pointer;
    opacity: .9;
  }

  &:active {
    cursor: pointer;
    opacity: .8;
  }
}

.searcher__field-input {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  background: #E8F3FA;
  border-bottom-right-radius: 1.6rem;
  border-top-right-radius: 1.6rem;

  .icon-search1 {
    &:before {
      font-size: 2.2rem;
    }
  }

  input {
    height: 66px;
    padding: 0 0.4rem;
    display: inline-flex;
    align-items: center;
    border: none;
    background-color: transparent;
    cursor: text;
    width: 100%;

    &:focus,
    &:active {
      outline: none;
      border: none;
    }
  }
}

.searcher__dropdown {
  $bg: $text-secondary-color;
  position: absolute;
  top: 5.7rem;
  left: 0;
  right: 0;
  background-color: $bg;

  > button {
    font-size: 1.5rem;
    line-height: 1.8rem;
    font-weight: 300;
    padding: 1.2rem 2.6rem;
    cursor: pointer;
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;

    &:hover,
    &:focus {
      outline: none;
    }

    &:hover {
      background-color: darken($bg, 4%);
    }

    &:active {
      background-color: darken($bg, 8%);
    }
  }
}

.searcher__field-button {
  $bg: #0075c8;
  height: 6rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.6rem;
  margin-right: 0.3rem;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0.3rem;
  background-color: darken($bg, 2%);
  color: #ffffff;
  padding: 0 3rem;

  &:hover {
    > i {
      &:before {
        color: #ffffff;
        font-size: 2.4rem;
      }
    }

    background-color: darken($bg, 4%);
  }

  &:active {
    > i {
      &:before {
        color: #ffffff;
        font-size: 2.4rem;
      }
    }

    background-color: darken($bg, 6%);
  }

  > i {
    &:before {
      color: #ffffff;
      font-size: 2.4rem;
    }
  }
}

.searcher__mode-container {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
}

.searcher__mode-item {
  $clr: #ffffff;
  font-size: 1.8rem;
  font-weight: 500;
  color: $clr;
  position: relative;
  padding: 0 0.6rem;
  cursor: default;
  text-transform: capitalize;

  &.active {
    &:after {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      width: 100%;
      height: 0.2rem;
      left: 0;
      right: 0;
      background-color: white;
      border-bottom-left-radius: 0.2rem;
      border-top-left-radius: 0.2rem;
    }
  }

  &:hover:not(.active) {
    -webkit-text-fill-color: lighten($clr, 20%);
    -webkit-text-stroke-width: 0.045rem;
    -webkit-text-stroke-color: lighten($clr, 20%);
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      bottom: -0.5rem;
      width: 100%;
      height: 0.2rem;
      left: 0;
      right: 0;
      background-color: white;
      border-bottom-left-radius: 0.2rem;
      border-top-left-radius: 0.2rem;
    }
  }

  &:active {
    -webkit-text-fill-color: lighten($clr, 30%);
    -webkit-text-stroke-width: 0.045rem;
    -webkit-text-stroke-color: lighten($clr, 30%);
  }
}

.searcher__mode-item + .searcher__mode-item {
  margin-left: 5.2rem;
}

@media only screen and (max-width: 1080px) {
  div.searcher__field {
    padding-left: 0;
  }

  div.searcher__field-city span {
    display: none;
  }

  div.searcher__field-input {
    margin-left: 0;
  }

  .home__banner-text {
    .searcher__field-province-dropdown,
    .searcher__field-re-dropdown {
      .popover-content {
        width: auto !important;
        left: 0;
        transform: translateX(0);
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .searcher__field-province-dropdown,
  .searcher__field-re-dropdown {
    .popover-content {
      width: auto !important;
      left: 0;
      transform: translateX(0);
    }
  }
}

@media only screen and (max-width: 656px) {
  img.home__banner-logotype {
    max-width: 25rem;
    max-height: 6.6rem;
  }

  .home__banner-text h4 p {
    line-height: 4rem;
  }

  .home__banner-text {
    top: 21rem;
  }

  div.searcher__field {
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding-right: 0;
    background-color: transparent;
  }

  .popover-container.searcher__field-province-dropdown-wrapper,
  .popover-container.searcher__field-re-dropdown-wrapper,
  .popover-container.searcher__field-price-dropdown-wrapper {
    width: 100%! important;
    background-color: #ffffff;
    border-left: none !important;
    border-radius: 1.6rem;
  }

  .popover-container + .popover-container {
    margin-top: 1rem;
  }

  .searcher__field-button {
    position: static;
    width: 100%;
    margin-top: 2.5rem;
  }
}
