@import "../../../scss/variables";

.select {
  position: relative;


  &-top {
    .select__dropdown {
      bottom: 4.7rem;
      top: auto;
    }
  }
}

.select__dropdown {
  width: 100%;
  position: absolute;
  top: 4.7rem;
  z-index: 10;
  background-color: #ffffff;
  box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.2509803922);
  border-radius: $border-radius;
  overflow: hidden;

  > div {
    max-height: 18.4rem;
    overflow: auto;
  }
}

.select__trigger {
  border-radius: $border-radius;
}

.select__trigger,
.select__dropdown-item {
  height: 4.6rem;
  background-color: transparent;
  padding: 0 3.4rem 0 2.4rem;
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 100%;
  font-weight: 300;
  color: $text-base-color;
  width: 100%;
  border: 0.1rem solid transparent;
  text-align: left;

  &:not(&:disabled) {
    cursor: pointer;
    &:hover {
      background-color: darken($field-background, 2%);
    }

    &:focus {
      background-color: darken($field-background, 2%);
      border-color: 0.1rem solid darken($field-background, 3%);
      outline: none;
    }
  }

  .select__trigger--text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .icon-arrow-down {
    position: absolute;
    right: 1.7rem;

    &:before {
      color: $text-base-color;
      font-size: 2.4rem;
    }
  }

  &:disabled {
    background-color: lighten($field-background, 1%);
  }
}

.select__trigger {
  background-color: $field-background;
}

.select__dropdown-item + .select__dropdown-item {
  border-top: 0.1rem solid darken($field-background, 3%);
  margin-top: -0.1rem;
}

.select__prefix-template {
  margin-right: 1rem;
}

.select__dropdown-item--placeholder,
.select__trigger--placeholder {
  color: rgb(117, 117, 117);
  font-weight: 300;
}

.select__trigger-invalid {
  border-width: 0 0 0 0.4rem;
  border-style: solid;
  border-color: rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) $clr-bf1650;
  border-image: initial;
}
