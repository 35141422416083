@import "../../../../scss/variables";

.real-estate__card {
  border-radius: 1.6rem;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0 0.4rem 1.4rem 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;

  &:hover {
    text-decoration: none;
    box-shadow: 0 0.4rem 2.4rem 0.4rem rgba(0, 0, 0, 0.15);
  }
}

.real-estate__card-preview {
  max-height: 24rem;
  min-height: 24rem;
  height: 240px;
  object-fit: cover;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  > svg {
    width: 100%;
    height: 100%;
    object-fit: cover;

    path {
      fill: #E1DACC;
    }
  }
}

.real-estate__card-details {
  padding: 2rem 1.4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.real-estate__card-marketing-address {
  font-size: 1.8rem;
  line-height: 2.16rem;
  font-weight: 600;
  color: rgba(12, 12, 12, 1);
  text-transform: uppercase;
}

.real-estate__card-presentation-address {
  font-size: 1.6rem;
  line-height: 1.92rem;
  font-weight: 300;
  color: rgba(12, 12, 12, 1);
  display: flex;
  align-items: flex-start;
  margin-top: 0.7rem;

  > i {
    margin-right: 0.8rem;
  }
}

.real-estate__card-description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.9rem;
}

.real-estate__card-description-price {
  font-size: 1.8rem;
  line-height: 2.0rem;
  font-weight: 700;
  color: rgba(0, 117, 200, 1);
  margin-top: auto;
}

.real-estate__card-description-metrics {
  display: flex;
  align-items: center;
}

.real-estate__card-description-metrics-item {
  font-size: 1.15rem;
  line-height: 1.47rem;
  font-weight: 600;
  color: rgba(12, 12, 12, 1);

  > i {
    margin-right: 0.4rem;
  }
}

.real-estate__card-description-metrics-item + .real-estate__card-description-metrics-item {
  margin-left: 0.65rem;
}
