@import "../../../scss/variables";

.field-phone-wrapper {
  position: relative;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  font-size: 1.4rem;

  &.field-phone-wrapper-2 {
    .field-phone-dropdown {
      left: 3.1rem;
    }
  }

  &.field-phone-wrapper-3 {
    .field-phone-dropdown {
      left: 1.8rem;
    }
  }

  &.field-phone-wrapper-4 {
    .field-phone-dropdown {
      left: 0.8rem;
    }
  }

  &.field-phone-wrapper-5 {
    .field-phone-dropdown {
      left: 0.2rem;
    }
  }
}

.field-phone-dropdown-wrapper {
  overflow: hidden;
  position: absolute;
  width: 6.9rem;
  left: 0;
  bottom: 0;
  top: 0;

  .icon-arrow-down {
    right: -0.2rem;
    bottom: 0;
    top: 0;
    margin: auto;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    pointer-events: none;
  }
}

.field-phone-dropdown {
  border-color: transparent;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: -0.1rem;
  bottom: 0;
  left: 0;


  &:focus,
  &:active {
    border-color: transparent;
    background-color: transparent;
    outline: none;
  }
}

.field-phone {
  height: 4.6rem;
  background-color: $field-background;
  padding: 0 2.4rem 0 6.9rem;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 1.4rem;
  line-height: 100%;
  font-weight: 300;
  color: $text-base-color;
  width: 100%;
  border: 0.1rem solid transparent;
  transition: all .3s;
  border-radius: $border-radius;

  &:not(&:disabled) {
    cursor: text;
    &:hover {
      background-color: darken($field-background, 2%);
    }

    &:focus {
      background-color: darken($field-background, 2%);
      border-color: 0.1rem solid darken($field-background, 3%);;
      outline: none;
    }
  }

  &:disabled {
    background-color: lighten($field-background, 1%);
  }
}

.field-phone--invalid {
  border-width: 0 0 0 0.4rem;
  border-style: solid;
  border-color: rgb(191, 22, 80) rgb(191, 22, 80) rgb(191, 22, 80) $clr-bf1650;
  border-image: initial;
}
