@import "../../../../scss/variables";
$back: $clr-1C1B1F;

.ReactModal__Content-buy-modal {
  max-width: 48rem !important;
  position: relative;

  .modal-header div[class*=col] {
    height: 5.2rem;
    line-height: 5.2rem;
  }

  .real-estate__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .real-estate__logotype {
    width: 13rem;
  }
  .real-estate__subtitle {
    height: 1.6rem;
    line-height: 2rem;
    color: $text-base-color;
    font-size: 1.4rem;
  }
  .real-estate__description {
    font-size: 16px;
    font-weight: 500;
    margin: 1rem 0;
  }

  .modal-header {
    > .row {
      padding: 0 1rem;
    }
  }

  .modal-content {
    padding-top: 1rem;
    padding-bottom: 3rem;
  }

  .modal-actions {
    padding-top: 0;
    padding-bottom: 2rem;
  }

  .field-phone,
  .field-regexp,
  .field-text,
  .select__trigger--text,
  .select__trigger--placeholder {
    font-size: 1.6rem !important;
  }

  div.privacy {
    padding-bottom: 2rem;
    display: flex;
    justify-content: center;

    .checkbox {
      justify-content: center;

      &-invalid + a {
        color: #bf1650 !important;
      }
    }
  }

  label.checkbox + a {
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-left: 0.45rem;
    cursor: pointer;
    font-size: 1.6rem;
    font-weight: 400;
    -webkit-user-select: none;
    user-select: none;
    color: #221122;
  }

  .button[type="submit"] + .button {
    margin-top: 1rem;
  }
}

@media only screen and (max-width: 768px) {
  .ReactModal__Content-buy-modal {
    border-radius: 0 !important;
    height: 100%;

    .modal-header {
      border-radius: 0;
      div[class*='col'] {
        padding: 0 1.2rem;
      }

      div[class*='col']:last-child {
        text-align: right;
      }

      .row {
        padding: 0;
      }
    }

    .field-regexp,
    .field-text,
    .field-phone,
    .select__trigger,
    .select__dropdown-item {
      height: 6.6rem;
      font-size: 1.6rem;
    }

    .field-textarea,
    .field-postfix {
      font-size: 1.6rem;
    }

    .select__dropdown {
      top: 6.6rem;
    }

    .modal-content {
      padding: 0;
      overflow: auto;
      border-radius: 0;

      .button--small {
        font-size: 1.4rem;
        height: 5.6rem;
      }

      .col-xs-12 {
        padding: 0.5rem;
      }
    }

    .ta-right {
      text-align: left;
      padding-bottom: 0;
      padding-left: 4rem;
    }

    form {
      height: 100%;
    }

    .modal-content.modal-content-step1 {
      height: calc(100% - 22rem);
    }

    .modal-actions {
      padding: 0;
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: $text-secondary-color;
      border-radius: 0;

      div[class*='col'] {
        padding: 1rem
      }

      .button {
        height: 6.6rem;
        font-size: 1.6rem;
      }
    }
  }
}

@media only screen and (max-width: 468px) {
  .ReactModal__Content-buy-modal {
    .modal-content {
      .col-xs-12 {
        padding: 0.5rem;
      }
    }
  }
}
