@import "../../../../scss/variables";

@keyframes placeHolderShimmer{
  0%{
    background-position: -46.8rem 0
  }
  100%{
    background-position: 46.8rem 0
  }
}

.skeleton {
  overflow: hidden;
  background-color: $text-secondary-color;
}

.skeleton__rect {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, darken($field-background, 4%) 8%, #e6e6e6 18%, darken($field-background, 8%) 33%);
  background-size: 95rem 10.4rem;

  &--w-full {
    width: 100%;
  }

  &--w-36 {
    width: 3.6rem;
  }

  &--w-76 {
    width: 7.6rem;
  }

  &--h-20 {
    height: 2rem;
  }
}

.real-estate__card--skeleton {
  svg {
    width: 100%;
    max-height: 24rem;
    height: auto;
  }

  path {
    fill: #E1DACC;
  }

  .real-estate__card-description-metrics-item {
    display: flex;
    align-items: center;
  }
}
