@import "../../scss/variables";

.home__banner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 87rem;
  grid-column-gap: 0;
  grid-row-gap: 0;
  align-content: center;
  justify-content: center;
  position: relative;
}

.home__banner-scroller {
  position: absolute;
  top: 5rem;
  height: 5rem;
  width: 100%;
}

.home__banner-logotype {
  max-width: 25.6rem;
  width: auto;
  height: auto;
}

.home__banner-image {
  grid-area: 1 / 1 / 2 / 2;

  > img {
    display: grid;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none
  }
}

.home__banner-text {
  position: absolute;
  top: 23.4rem;
  margin: auto;
  padding-left: 15.2rem;

  h4 {
    color: $text-base-color;
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 300;
    margin: 0;
    max-width: 640px;

    p {
      font-weight: 400;
      font-size: 4.8rem;
      line-height: 5.6rem;
      color: #ffffff;
      text-transform: uppercase;
    }
  }

  p {
    margin: 0;
  }
}

.row-cards-title {
  position: relative;
  z-index: 1;
}

.row-cards {
  position: relative;
  z-index: 2;

  > div:first-child {
    padding-right: 2rem;
  }

  > div:last-child {
    padding-left: 2rem;
  }
}

.company-workflow-card-action {
  margin-top: 40px;
  display: flex;
  justify-content: center;

  button {
    max-width: 38.4rem;
    width: 100%;
  }
}

.home__banner-search {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home__banner-gradient {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 37%;
  background: linear-gradient(
                  to bottom,
                  rgba(255, 255, 255, 0),
                  rgba(255, 255, 255, 0.65),
                  rgba(255, 255, 255, 1)
  );
}

.home__company-workflow {
  max-width: 121.2rem;
  margin: 0 auto 0 auto;
  position: relative;
  top: -13rem;
  display: flex;
  flex-direction: row;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow: hidden;
}

.workflow-row__section {
  width: 49%;
}

.workflow-row__name {
  font-size: 4.2rem;
  font-weight: 500;
  line-height: 6.51rem;
  letter-spacing: 0.02rem;
  text-align: center;
  color: rgba(0, 117, 200, 1);
  text-transform: uppercase;
  margin-top: -1rem;
}

.workflow-row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  min-height: 30.5rem;

  div:nth-child(3), div:nth-child(4), div:nth-child(5), div:nth-child(6), div:nth-child(7) {
    margin-top: 3.2rem;
  }
}

.workflow-row {
  display: flex;
  align-items: flex-start;
  width: 50%;
}

.workflow-row__separator {
  width: 1px;
  height: auto;
  background-color: rgba(130, 130, 130, 1);
  display: flex;
  justify-content: center;
  margin-left: 4rem;
  margin-right: 4rem;
}

.workflow-row__counter {
  width: 38px;
  min-width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1.6rem;
  background: rgba(0, 117, 200, 0.56);
  border-radius: 1.3rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0.02rem;
  color: #ffffff;
  border: 1px solid transparent;

  &--transparent {
    border-color: rgba(130, 130, 130, 1);
    background: transparent;
    color: rgba(0, 117, 200, 1);
  }
}

.workflow-row__title {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.16rem;
  letter-spacing: 0.02rem;
  max-width: 22rem;
  min-height: 3.8rem;
  display: flex;
  align-items: center;
}

.workflow-row__action {
  max-width: 384px;
  width: 100%;
  margin: 4.2rem auto 0 auto;
  display: block;
}

.home__best-offers {
  max-width: 121.2rem;
  margin: 3.7rem auto 0 auto;
  cursor: default;
  padding-left: 1rem;
  padding-right: 1rem;

  h2 {
    font-size: 4.2rem;
    font-weight: 400;
    line-height: 4.5rem;
    text-align: center;
    color: $text-base-color;
    text-transform: uppercase;
    margin-bottom: 5.3rem;
    margin-top: 0;
  }

  .home__best-offers-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(383px, 1fr));
    grid-gap: 2rem;
    align-items: stretch;
  }
}

.home__best-offers-action {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7.2rem;

  button {
    max-width: 22.2rem;
    width: 100%;
  }
}

.home__cards {
  padding-left: 1rem;
  padding-right: 1rem;

  h3 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3rem;
    text-align: left;
    color: $text-base-color;
    text-transform: uppercase;
  }

  p {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2.4rem;
    letter-spacing: 0.03em;
    text-align: left;
    color: $text-base-color;
  }
}

.home__cards-content {
  flex-wrap: nowrap;

  div[class*="col"] {
    padding: 4.2rem 5rem;
  }

  div[class*="col"]:nth-child(1) {
    background-color: #E1DACC;
  }
  div[class*="col"]:nth-child(2) {
    background-color: $field-background;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  div[class*="col"]:nth-child(3) {
    background-color: $clr-5E452F;

    h3, p {
      color: $text-secondary-color;
    }
  }
}

.home__cards-actions {
  margin-top: -2.4rem;

  .button {
    max-width: 27.9rem;
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .home__cards-content {
    flex-wrap: wrap;

    div[class*="col"] {
      padding: 1.2rem 1.6rem;
    }

    div[class*="col"]:nth-child(1) {
      background-color: #E1DACC;
    }
    div[class*="col"]:nth-child(2) {
      background-color: $field-background;
      margin-left: 0;
      margin-right: 0;
    }
    div[class*="col"]:nth-child(3) {
      background-color: $clr-5E452F;
    }
  }
}

.home__about {
  margin-top: 11rem;
  margin-bottom: 11rem;
}

.home__about-content {
  flex-wrap: nowrap;

  img {
    max-height: 74.8rem;
    max-width: 51.3rem;
    width: 100%;
    height: 100%;
  }

  h3 {
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 4.5rem;
    text-align: left;
    color: $text-base-color;
    text-transform: uppercase;
  }

  p {
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 2.7rem;
    letter-spacing: 0.03rem;
    text-align: left;
    color: $text-base-color;
  }

  p + p {
    margin-top: 2.4rem;
  }

  .home__about-actions {
    margin-top: 2.4rem;

    .button {
      max-width: 27.9rem;
      width: 100%;
    }
  }

  div[class*="col"]:nth-child(1) {
    padding-left: 0;
  }

  div[class*="col"]:nth-child(2) {
    padding-left: 5.6rem;
    padding-right: 15.6rem;
  }
}

.home__contacts {
  img {
    max-height: 45.5rem;
    max-width: 75.6rem;
    width: 100%;
    height: 100%;
    display: block;
  }

  .contacts-social {
    display: flex;
    align-items: center;
  }

  .social-icon {
    margin-right: 2.4rem;
    overflow: hidden;

    cursor: pointer;

    &:hover {
      opacity: 0.65
    }

    &:active {
      opacity: 0.45;
    }

    i {
      &:before {
        font-size: 7.1rem;
        color: #0075c8;
      }
    }

    i.icon-phone1 {
      &:before {
        font-size: 4.9rem;
        color: #0075c8;
        position: relative;
        top: -0.1rem;
      }
    }
  }
}

.home__contacts-content {
  div[class*="col"] {
    padding-bottom: 0;
  }

  div[class*="col"]:nth-child(1) {
    padding-left: 4.8rem;
  }
  div[class*="col"]:nth-child(2) {
    padding-right: 0;
  }

  h3 {
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 7.7rem;
    color: $text-base-color;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    line-height: 27px;
    color: $text-base-color;
    margin-top: 0;
  }

  .contacts-social {
    margin-top: 48px;
  }
}

.home__seo {
  position: absolute;
  z-index: -1;
  top: -200px;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@media only screen and (max-height: 960px) {
  .home__banner {
    grid-template-rows: 100vh;
  }
}

@media only screen and (max-width: 658px) {
  .home__banner {
    grid-template-rows: 100vh;
  }
}

@media only screen and (max-width: 980px) {
  .header__navigation-trigger {
    svg {
      path {
        stroke: $text-secondary-color;
      }
    }
  }

  .home__best-offers .real-estate-card-preview .real-estate-catalog-preview {
    max-width: 45.5rem;
  }
}

@media only screen and (max-width: 940px) {
  .home__banner-text h4 {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 870px) {
  .home__banner-image > img {
    object-position: 75%;
  }

  .home__cards-wrapper {
    flex-wrap: wrap;
  }

  .home__cards-card {
    width: 100%;
    padding-bottom: 68%;
  }

  .home__cards-card + .home__cards-card {
    margin-top: 0.8rem;
  }

  .home__cards-card-description {
    padding: 0;
  }
}

@media only screen and (max-width: 1120px) {
  .home__company-workflow {
    flex-wrap: wrap;
  }

  .workflow-row__section {
    width: 55%;
    margin: 0 auto;
  }

  .workflow-row__separator + .workflow-row__section {
    margin-top: 6.2rem;
  }

  .workflow-row__separator {
    display: none;
  }
}

@media only screen and (max-width: 870px) {
  .workflow-row__section {
    width: 75%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 768px) {
  .workflow-row__section {
    width: 85%;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 550px) {
  .workflow-row {
    width: 100%;
  }

  .workflow-row__title {
    width: 100%;
    max-width: 100%;
  }

  .workflow-row-container {
    > div:nth-child(2) {
      margin-top: 3.2rem;
    }
  }
}



@media only screen and (max-width: 920px) {
  .home__banner-text {
    padding-left: 7.6rem;
  }
}

@media only screen and (max-width: 768px) {
  .home__banner-text {
    width: 100% !important;
    padding: 0 1rem;
    z-index: 2;
  }

  .home__banner-gradient {
    bottom: -0.1rem;
    right: -0.1rem;
  }

  .home__company-workflow {
    margin-top: 6.3rem;
  }

  .company-workflow-card {
    padding: 1.6rem;
  }

  .home__best-offers {
    margin-top: -4.61rem;

    h2 {
      margin-bottom: 1.5rem;
    }
  }
}

@media only screen and (max-width: 1280px) {
  section.home__contacts,
  section.home__cards,
  section.home__headline,
  section.home__about,
  section.home__about{
    h3, h2 {
      font-size: 2.8rem;
      line-height: 3.6rem;
    }
  }

  .home__headline {
    padding-top: 3.6rem;
    padding-bottom: 3.6rem;
  }

  .home__about {
    margin-top: 3.6rem;
    margin-bottom: 6.5rem;
  }

  .home__parallax + .home__contacts{
    margin-top: 3.6rem;
  }
}

@media only screen and (max-width: 1080px) {
  .home__cards-content {
    flex-wrap: wrap;

    div[class*=col]:nth-child(2) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .home__contacts {
    .contacts-social {
      margin-top: 2.4rem;
    }

    .social-icon {
      width: 5.6rem;
      height: 5.6rem;
    }
  }
}

@media only screen and (max-width: 1040px) {
  div.home__contacts-content div[class*=col]:nth-child(1) {
    padding-left: 1rem;
    padding-bottom: 1rem;
  }
  .home__parallax + .home__contacts{
    margin-top: 3.6rem;
    margin-bottom: 3.6rem;
  }

  .home__about-content div[class*=col]:nth-child(2) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .home__best-offers .real-estate-card-preview .real-estate-catalog-preview {
    max-width: 48.4rem;
  }

  .row-cards {
    > div:first-child {
      padding-right: 1rem;
    }

    > div:last-child {
      padding-left: 1rem;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .home__about-content {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 656px) {
  .home__contacts {
    display: none;
  }

  .home__cards-actions {
    div[class*="col"] {
      padding: 0;
    }
  }

  img.home__banner-logotype {
    max-width: 25rem;
    max-height: 6.6rem;
    display: none;
  }

  .home__banner-text {
    padding: 0 1rem;

    h4 {
      font-size: 1.8rem;
      line-height: 2.7rem;
      font-weight: 300;
      margin: 0;

      p {
        font-weight: 400;
        font-size: 2.9rem;
        letter-spacing: -0.08rem;
      }
    }
  }

  .home__best-offers .real-estate-card-preview .real-estate-catalog-preview {
    max-width: 62rem;
  }

  .home__best-offers {
    margin-top: -4.8rem;
  }

  .company-workflow-card-action {
    width: 73%;
    margin: 0 auto;
  }

  .company-workflow-card-action + .swiper-title {
    margin-top: 6.4rem;
  }

  .swiper-sell {
    margin-bottom: 0;
  }

  .swiper-cards {
    margin-bottom: 2.4rem;
  }

  .home__parallax {
    display: none;
  }

  .home__headline img {
    max-width: 26rem;
    max-height: 6.6rem;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .button {
    width: 100% !important;
    max-width: 35.6rem !important;
  }

  .home {
    .searcher .municipality-combobox .field-prefix {
      left: 1.4rem;
    }

    .searcher .municipality-combobox .field-text {
      padding-left: 3.8rem;
    }

    .municipality-combobox .popover-content > button {
      padding-left: 3.8rem;
    }
  }

  .home__about,
  .home__contacts {
    h3, p {
      text-align: center;
    }
  }

  .home__contacts {
    .contacts-social {
      justify-content: center;
    }

    .social-icon {
      margin-left: 1.2rem;
      margin-right: 1.2rem;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (max-height: 640px) {
  .home__banner-text {
    top: 14.3rem;
  }
}
@media only screen and (max-width: 656px) {
  .swiper-slide .company-workflow-card-image {
    height: 12rem;
  }

  .card-top-description {
    margin-top: 2rem;
  }

  .company-workflow-card-bottom {
    font-size: 1.3rem;
    line-height: 1.9rem;
  }

  .home__best-offers .home__best-offers-cards {
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  }
}

@media only screen and (max-width: 468px) {
  .home__banner-text {
    top: 10rem !important;
  }
}
