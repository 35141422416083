@import "normalize.css/normalize.css";
@import "@drewbot/sass-flexbox-grid/public/sass-flexbox/main.min.css";
@import "react-image-gallery/styles/css/image-gallery.css";
@import 'swiper/swiper-bundle.css';
// @import 'swiper/css/effect-cards';
@import "scss/index";

:root {
  --rt-color-white: #fff !important;
  --rt-color-dark: #337ab7 !important;
  --rt-color-success: #8dc572 !important;
  --rt-color-error: #be6464 !important;
  --rt-color-warning: #f0ad4e !important;
  --rt-color-info: #337ab7 !important;
  --rt-opacity: 0.9 !important;
  --rt-transition-show-delay: 0.15s !important;
  --rt-transition-closing-delay: 0.15s !important;
}

body.ReactModal__Body--open {
  overflow: hidden;
  overscroll-behavior-y: contain;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Overlay {
  z-index: 10 !important;
  background-color: rgba(0, 0, 0, 0.65) !important;
  align-items: center;
  justify-content: center;
  display: flex;

  .ReactModal__Content {
    padding: 0 !important;
    background-color: $text-secondary-color;
    display: flex;
    flex-direction: column;
    max-width: 100.4rem;
    width: 100%;
    outline: none;
    border-radius: 2.4rem;
  }
}

html {
  //margin-left: calc(100vw - 100%);
  //margin-right: 0;
}

body, html {
  height: 100%;
}

#root {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  flex-shrink: 0;
  overflow: hidden;
  position: relative;
  margin-bottom: 3.6rem;

  &.content-padded {
    margin-top: 6.6rem;
  }

  > div:not(.home):not(.about) {
    max-width: 121.2rem;
    margin: 0 auto;
  }
}

.footer {
  margin-top: auto;
}

@media only screen and (max-width: 656px) {
  .content.content-padded {
    margin-top: 5.9rem;
  }

  .ReactModal__Overlay {
    .ReactModal__Content {
      .modal-content, .modal-actions {
        padding: 1rem;
      }
    }
  }
}
