@import "../../../scss/variables";

.municipality-combobox {
  $bg: $field-background;

  .municipality-combobox__trigger {
    display: flex;
    position: relative;
  }

  .field-text {
    padding-left: 5rem;
    padding-right: 4.2rem;
  }

  .field-prefix, .field-postfix {
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-flex;
    align-items: center;
  }

  .field-prefix {
    position: absolute;
    left: 2rem;

    &:before {
      font-size: 2.4rem;
      color: $text-base-color;
    }
  }

  .field-postfix {
    position: absolute;
    right: 1rem;

    &:before {
      font-size: 2rem;
      color: $clr-B8AC95;
    }
  }

  $close: $clr-1C1B1F;

  .icon-close {
    width: 2.4rem;
    height: 2.4rem;
    line-height: 2.4rem;
    cursor: pointer;

    &:before {
      font-size: 18px;
      color: lighten($close, 55%);
    }

    &:hover {
      &:before {
        color: lighten($close, 25%);
      }
    }

    &:active {
      &:before {
        color: lighten($close, 35%);
      }
    }
  }

  .popover-content {
    z-index: 4;
    margin-top: 0.2rem;
    overflow: hidden;

    > div {
      max-height: 15.6rem;
      overflow: auto;
    }

    button {
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 300;
      padding: 1.2rem 2.6rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      width: 100%;
      background-color: transparent;
      border-color: transparent;
      text-align: left;
      display: flex;

      &:focus, &:active {
        outline: none;
        border-color: transparent;
      }

      &:hover {
        background-color: darken($bg, 4%);
      }

      &:active {
        background-color: darken($bg, 8%);
      }
    }

    .no-match {
      font-size: 1.5rem;
      line-height: 1.8rem;
      font-weight: 300;
      padding: 1.2rem 2.6rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      background-color: transparent;
      border-color: transparent;
      text-align: left;
      cursor: default;
      &:hover {
        background-color: transparent;
      }
      &:active {
        background-color: transparent;
      }

      &.no-match {
        color: darken($text-secondary-color, 55%);
      }
    }
  }
}
