.calculator {
  text-align: center;
  margin-top: 7.2rem;
  margin-bottom: 6.4rem;
  cursor: default;

  h2 {
    font-size: 4.2rem;
    line-height: 5.6rem;
    text-transform: uppercase;
    font-weight: 400;
    margin: 0;

    span {
      color: #0075C8;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 2rem;
    color: #828282;
    margin: 0;
  }

  .row:first-child > div {
    padding-bottom: 0;
    margin-bottom: 0.1rem;
  }

  .row:last-child {
    margin-top: 0.7rem;
  }
}

.calculator-action {
  margin-top: 2.4rem;

  button {
    max-width: 36.5rem;
    width: 100%;
  }
}

@media only screen and (max-width: 656px) {
  section.calculator {
    margin-bottom: 4.2rem !important;
  }
}
