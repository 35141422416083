@import "../../../../scss/variables";

.catalog-empty-wrapper-placeholder {
  min-height: 40rem;
  padding-left: 1rem;
  padding-right: 1rem;
  position: relative;
  margin-bottom: 4.6rem;
}

.catalog-empty-placeholder {
  position: absolute;
  top: 50%;
  transform: translateY(-45%);
  left: 0;
  right: 0;

  .icon-location {
    &:before {
      font-size: 6.4rem;
      color: rgba(0, 117, 200, 0.5607843137);
    }
  }

  .catalog-empty-placeholder-row {
    max-width: 56rem;
    margin: 1.2rem auto 3.2rem auto;
  }

  .catalog-empty-placeholder__description {
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 4.5rem;
    text-align: center;
    color: $text-base-color;
    text-transform: uppercase;
  }

  .button {
    max-width: 24rem;
  }
}
