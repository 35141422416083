*, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html { font-size: 62.5%; }

body {
  margin: 0;
  font-family: $font-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.4rem;
  // background-color: $background;
}

.section-content {
  h2 {
    font-size: 4.5rem;
    font-weight: 500;
    line-height: 4.5rem;
    text-align: center;
    color: $text-base-color;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  h3 {
    font-size: 2.5rem;
    font-weight: 500;
    line-height: 3rem;
    text-align: left;
    color: $text-base-color;
    text-transform: uppercase;
  }

  h4 {
    color: $text-secondary-color;
    font-size: 1.8rem;
    line-height: 2.7rem;
    font-weight: 300;
    margin: 2.4rem 15% 5.6rem 15%;
  }

  p {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2.7rem;
    letter-spacing: 0.03rem;
    text-align: left;
    color: $text-base-color;
  }

  p + p {
    margin-top: 2.4rem;
  }

  p {
    margin: 0;
  }
}

.extras-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  max-width: 68.9rem;
  margin: 0 auto;

  .extra {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
    margin-bottom: 0.8rem;
  }

  .button {
    text-transform: capitalize;
    display: inline-flex;
  }
}
