@import "../../scss/variables";

.terms-of-use {
  padding-left: 1rem;
  padding-right: 1rem;

  .headline {
    font-size: 4.1rem;
    line-height: 5.06rem;
  }
}
