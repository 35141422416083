.headline {
  font-size: 4.8rem;
  font-weight: 400;
  line-height: 5.76rem;
  letter-spacing: 0.02rem;
  position: relative;
  color: rgba(12, 12, 12, 1);
  display: inline-block;
  margin-bottom: 4.6rem;
  text-transform: uppercase;

  &:after {
    content: '';
    height: 4px;
    border-radius: 16px;
    width: 120%;
    position: absolute;
    left: -6px;
    bottom: -14px;
    background-color: rgba(0, 117, 200, 0.09);
  }
}
