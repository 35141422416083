@import "../../../scss/variables";

.checkbox {
  display: inline-flex;
  align-items: center;
  position: relative;
  padding-left: 2.4rem;
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: $clr-221122;

  &-invalid {
    color: $clr-bf1650;

    .checkmark {
      border-color: $clr-bf1650;
    }
  }
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  height: 1.6rem;
  width: 1.6rem;
  margin: auto;
  border: 0.1rem solid $clr-221122;
  border-radius: 0.4rem;
}

.checkbox:not(.checkbox-invalid):hover input ~ .checkmark {
  border-color: $clr-B8AC95;
}

.checkbox input:checked ~ .checkmark {
  background-color: $clr-704B29;
  border-color: transparent;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: inline-flex;
}

.checkbox .checkmark:after {
  left: 0.4rem;
  top: 0.1rem;
  width: 0.4rem;
  height: 0.8rem;
  border: solid white;
  border-width: 0 0.2rem 0.2rem 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
