.catalog-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(383px, 1fr));
  grid-gap: 2rem;
  align-items: stretch;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

@media only screen and (max-width: 656px) {
  .catalog {
    padding-bottom: 2.4rem;
  }

  .real-estate-address-location {
    font-weight: 200;
  }

  .real-estate-feature-separator {
    margin-right: 0.6rem;
    margin-left: 0.6rem;
  }

  .real-estate-feature-separator {
    height: 1.2rem;
  }

  .real-estate-address {
    margin-top: 1.2rem;
  }

  .real-estate-features {
    margin-top: 1.4rem;
    font-size: 1.2rem;
  }

  .catalog-empty-placeholder__description {
    font-size: 3.6rem !important;
  }

  .real-estate-feature {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 656px) {
  .real-estate-card-preview .real-estate-catalog-preview {
    max-width: 65.6rem;
  }

  .catalog-cards {
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
  }
}
