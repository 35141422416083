.buy-modal__real-estates {
  display: flex;
  align-items: center;

  .button + .button {
    margin-left: 2rem;
  }

  .button {
    font-size: 1.4rem;
  }
}
