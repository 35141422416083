$font-base: 'Poppins', sans-serif;

$text-base-color: #000000;
$text-secondary-color: #ffffff;

$field-background: #eff8ff;
// $background: $text-secondary-color;
$background: rgba(248, 247, 240, .45);
$clr-1C1B1F: #1C1B1F;
$clr-E1DACC: #b4e0ff;
$clr-704B29: #0075c8;
$clr-221122: #221122;
$clr-bf1650: #bf1650;
$clr-B8AC95: #B8AC95;
$clr-49454F: #49454F;
$clr-5E452F: #5E452F;

$border-radius: 0.8rem;
