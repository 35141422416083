@import "../../../scss/variables";

@keyframes fadeInHeight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

header {
  position: fixed;
  top: 0;
  margin: 0 auto;
  width: 100%;
  z-index: 9;
  animation: all 0.33ms ease-in;

  &.header--scrolled {
    box-shadow: 0 0.4rem 1.5rem 0 #00000040;

    .header {
      background-color: #fcfbf8;
      height: 6.5rem;
    }

    .logotype {
      background-image: url('./logotype_dark.png');
    }

    .header__navigation-small {
      background-color: $text-secondary-color;
      box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.2509803922);
      padding-bottom: 1rem;
    }

    .header__navigation-item {
      color: $text-base-color;

      &:hover {
        background: $field-background;
      }
    }

    .language-switcher-trigger {
      color: $text-base-color;

      &:hover {
        background: $field-background;
      }
    }

    .language-switcher-icon {
      &:before {
        color: $text-base-color;
      }
    }

    .header__navigation-trigger {
      svg {
        path {
          stroke: $text-base-color;
        }
      }
    }
  }
}

.header {
  height: 9rem;
  padding: 0 13.6rem;
  display: flex;
  align-items: center;
  position: relative;
  transition: height 1s, background 0.85ms;
}

.logotype {
  width: 12rem;
  height: 1.8rem;
  background-image: url('./logotype_white.png');
  background-repeat: no-repeat;
  background-size: contain;
}

.header__logotype {
  padding-top: 0;
  padding-bottom: 0;
}

.header__navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: -2.1rem;
  padding: 0 1rem;
}

.header__navigation-item {
  font-size: 1.6rem;
  line-height: 4.6rem;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
  padding-right: 2.1rem;
  padding-left: 2.1rem;
  height: 4.6rem;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  border-radius: 1rem;

  &:hover {
    background: #0075C817;
  }
}

.header__navigation-separate {
  width: 0.1rem;
  height: 1.6rem;
  background-color: $text-base-color;
}

.header__language-switcher {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header__navigation-small,
.header__navigation-trigger {
  display: none;
}

.header__navigation-trigger {
  width: 4.8rem;
  height: 4.8rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    opacity: .85;
  }

  &:active {
    opacity: .75;
  }
}

.header__navigation-small {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 9;

  .header__navigation-item {
    padding: 1.2rem 1.6rem;
    display: flex;
    align-items: center;
  }
}

@media only screen and (max-width: 1330px) {
  .nav-contacts {
    display: none;
  }
}

@media only screen and (max-width: 1280px) {
  .nav-how-it-works {
    display: none;
  }
}

@media only screen and (max-width: 980px) {
  .header {
    padding: 0 1.6rem;
  }
  .header__logotype {
    padding-left: 0;
  }
  .header__navigation,
  .header__language-switcher {
    display: none;
  }
  .header__navigation-small {
    display: flex;
    flex-direction: column;
  }
  .header__navigation-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (max-width: 656px) {
  .header {
    height: 5.5rem !important;

    div[class*="col"] {
      padding: 0;
    }

    &.header--scrolled {
      .header {
        height: 5.5rem !important;
      }
    }
  }

  .logotype {
    width: 12rem;
    height: 1.9rem;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 2.6rem;
  }

  .header__navigation-item,
  .language-switcher {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
}
