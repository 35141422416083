@import "../../scss/variables";

.extra-services {
  padding-left: 1rem;
  padding-right: 1rem;
}

.extra-card {
  padding: 4rem 2rem;
  border: 1px solid rgba(130, 130, 130, 1);
  border-radius: 1.6rem;
  display: flex;
  min-height: 32.9rem;

  &:nth-child(odd) {
    border-color: transparent;
    background-color: rgba(0, 117, 200, 0.09);
  }
}

.extra-card + .extra-card {
  margin-top: 2rem;
}

.extra-card__left-block {
  max-width: 48.6rem;
  width: 100%;
  display: flex;
  align-items: flex-start;
  max-height: 14.6rem;
}

.extra-card__title {
  max-width: 17.7rem;
  font-size: 2.2rem;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 2.64rem;
  color: rgba(12, 12, 12, 1);
  margin-left: 3.1rem;
  display: flex;
  align-items: center;
  margin-top: 0;
  margin-bottom: 0;
  max-height: 14.6rem;
  height: 100%;
}

.extra-card__list-title {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.16rem;
  letter-spacing: 0.02rem;
}

.extra-card__list {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.72rem;
  letter-spacing: 0.02rem;
  color: rgba(130, 130, 130, 1);
  padding-left: 2.2rem;
  margin-top: 1rem;
  margin-bottom: 2.7rem;
}

.extra-card__action {
  max-width: 22.2rem;
}

@media only screen and (max-width: 1030px) {
  .extra-card__left-block {
    max-width: 38.6rem;
  }
}

@media only screen and (max-width: 900px) {
  .extra-card {
    flex-wrap: wrap;
  }

  .extra-card__left-block {
    margin-bottom: 2rem;
  }

  .extra-card__left-block, .extra-card__right-block {
    max-width: 100%;
    width: 100%;
  }
}

@media only screen and (max-width: 656px) {
  .extra-card__left-block {
    text-align: center;
    flex-direction: column;
    max-height: inherit;
  }

  .extra-card__img,
  .extra-card__title,
  .extra-card__action {
    margin: 0 auto;
    display: block;
  }

  .extra-card__img {
    margin-bottom: 2rem;
  }

  .extra-card__list-title {
    text-align: center;
  }

  .extra-card + .extra-card {
    margin-top: 5rem;
  }

  .extra-card__list-item + .extra-card__list-item {
    margin-top: 0.6rem;
  }
}
