@import "../../../../../scss/variables";

.language-switcher {
  position: relative;
}

.language-switcher-trigger {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  line-height: 4.6rem;
  font-weight: 500;
  color: $text-secondary-color;
  cursor: pointer;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  padding-right: 1.6rem;
  padding-left: 1.6rem;
  height: 4.6rem;
  border-radius: 1rem;

  &:hover {
    background: #0075C817;
    border: none;
    outline: none;
  }
}

.language-switcher-icon {
  margin-left: 0.6rem;

  &:before {
    color: $text-secondary-color;
    font-size: 1.4rem;
  }
}

.language-switcher-dropdown {
  position: absolute;
  top: 4.8rem;
  right: 0;
  background-color: $text-secondary-color;
  z-index: 2;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 0.4rem 1.5rem 0 rgba(0, 0, 0, 0.2509803922);
}

.language-switcher-dropdown-item {
  height: 4.6rem;
  background-color: $text-secondary-color;
  padding: 0 5.6rem 0 2.4rem;
  display: inline-flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 100%;
  font-weight: 300;
  color: $text-base-color;
  width: 100%;
  border: 0.1rem solid transparent;

  &:not(&:disabled) {
    cursor: pointer;
    &:hover {
      background-color: darken($field-background, 2%);
    }

    &:focus {
      background-color: darken($field-background, 2%);
      border-color: 0.1rem solid darken($field-background, 3%);
      outline: none;
    }
  }
}

@media only screen and (max-width: 980px) {
  .language-switcher {
    height: 4.6rem;
    display: flex;
    align-items: center;
  }

  .language-switcher-trigger {
    width: 100%;
  }

  .language-switcher-dropdown {
    left: 0;
    right: 0;
    top: 4.6rem;
  }
}
