@import "../../scss/variables";

.about__banner {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 76rem;
  grid-column-gap: 0;
  grid-row-gap: 0;
  align-content: center;
  justify-content: center;
  position: relative;
}

.about__banner-scroller {
  position: absolute;
  top: 5rem;
  height: 5rem;
  width: 100%;
}

.about__banner-logotype {
  max-width: 25.6rem;
  width: auto;
  height: auto;
}

.about__banner-image {
  grid-area: 1 / 1 / 2 / 2;

  > img {
    display: grid;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none
  }
}

.about__banner-text {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 62.5rem;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  text-align: center;

  h1 {
    font-size: 4.8rem;
    font-weight: 400;
    line-height: 5.76rem;
    letter-spacing: 0.02rem;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 117, 200, 1);
    margin-top: 0;
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.92rem;
    letter-spacing: 0.02rem;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(12, 12, 12, 1);
  }

  button {
    margin-top: 4.4rem;
    max-width: 36.5rem;
  }
}

.about__team {
  min-height: 80.5rem;
  background: rgba(0, 117, 200, 0.09);
  position: relative;

  h2 {
    font-size: 4.2rem;
    font-weight: 400;
    line-height: 4.41rem;
    letter-spacing: 0.02rem;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(12, 12, 12, 1);
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 6rem;
    padding-bottom: 2.4rem;
    text-transform: uppercase;
  }

  .polygon {
    width: 100%;
    height: 36rem;
    position: absolute;
    bottom: 0;

    svg {
      height: 100%;
      width: 100%;
      .main {
        fill: white;
      }
    }
  }
}

.about__team-cards {
  max-width: 121.2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 2rem;
  align-items: stretch;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 2rem auto;
  position: relative;
  z-index: 1;
}

.about__team-cards-item {
  border-radius: 1.6rem;
  overflow: hidden;
  text-decoration: none;
  box-shadow: 0 0.4rem 1.4rem 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  img {
    height: 100%;
    object-fit: cover;
  }

  h4, h5 {
    line-height: 1.92rem;
    letter-spacing: 0.02rem;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  h4 {
    font-size: 1.6rem;
    font-weight: 600;
    margin-top: 0.55rem;
    margin-bottom: 0.25rem;
  }

  h5 {
    margin-top: 0.25rem;
    margin-bottom: 0.55rem;
    font-size: 1.5rem;
    font-weight: 400;
  }
}

.about__team-progress {
  max-width: 121.2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 2rem;
  align-items: stretch;
  padding-left: 1rem;
  padding-right: 1rem;
  margin: 9rem auto 2rem auto;
  position: relative;
  z-index: 1;
}

.about__team-progress-item {
  border: 1px solid rgba(0, 0, 0, 1);
  background-color: #ffffff;
  border-radius: 3.1rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6rem;
  letter-spacing: 0.02rem;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  min-height: 138px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2.4rem 3.9rem;

  i {
    margin-right: 1.4rem;
    position: relative;
    top: 2px;

    &:before {
      font-size: 2.8rem;
      color: rgba(0, 117, 200, 1);
    }
  }

  .about__team-progress-item-description {
    display: flex;
    flex-direction: column;
  }

  h4 {
    font-size: 3.2rem;
    font-style: italic;
    font-weight: 600;
    line-height: 3.36rem;
    letter-spacing: 0.02rem;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
  }

  p {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.8rem;
    letter-spacing: 0.02rem;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin: 0;
    color: rgba(130, 130, 130, 1);
  }
}

.about__team-contact-form {
  max-width: 55rem;
  margin: -8.5rem auto 3.2rem auto;
  position: relative;
  z-index: 3;

  .modal-content {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .modal-actions {
    padding-top: 1rem;

    .checkbox {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2.1rem;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: rgba(130, 130, 130, 1);
    }
  }
}

.about__team-background {
  position: absolute;
  z-index: 0;
}

.about__team-background-2 {
  position: absolute;
  z-index: -1;
  right: 2.2rem;
  top: -8.6rem;
}

.about__team-background-3 {
  position: absolute;
  z-index: -1;
  left: -10rem;
  bottom: -5.4rem;
}

.about__team-background-1 {
  position: absolute;
  z-index: 0;
  left: 0;
}

@media only screen and (max-width: 1214px) {
  .about__team-contact-form {
    margin: 0.5rem auto 3.2rem auto;
  }

  .about__banner {
    grid-template-rows: calc(100vh - 5.9rem);
  }
}

@media only screen and (max-width: 1040px) {
  .about__team-contact-form .modal-actions .checkbox {
    margin-bottom: 2rem;
  }
}
