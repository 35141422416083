@import "../../../scss/variables";

.field-textarea {
  background-color: $field-background;
  padding: 1.4rem 2.4rem;
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 100%;
  font-weight: 300;
  color: $text-base-color;
  width: 100%;
  border: 0.1rem solid transparent;
  border-radius: $border-radius;

  &:not(&:disabled) {
    cursor: text;
    &:hover {
      background-color: darken($field-background, 2%);
    }

    &:focus {
      background-color: darken($field-background, 2%);
      border-color: 1px solid darken($field-background, 3%);;
      outline: none;
    }
  }

  &:disabled {
    background-color: lighten($field-background, 1%);
  }
}
